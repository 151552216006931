import React, {useEffect, useState} from "react";
import Modal from "../../modal";
import axios from "axios";
import Toast from "../../toast";
import Loader from "../../loader";
import {baseUrl} from "../../../utils/baseUrl";
import SelectComponent from "../../selectBox";


function EditGroupModal({getFunction, openModal, setOpenModal}) {
  const [yearData, setYearData] = useState({
    name: openModal?.grade,
    description: openModal?.description,
    loading: false,
  });
  const [toast, setToast] = useState(false);



  const handelEdit = async()=>{
    let data_to_send={
      subject_name:openModal.subject_name,
      subject_id:openModal.subject_id
    }

     console.log(data_to_send);
    
    await axios.post
    (baseUrl + 'edit_cours_subject.php' , JSON.stringify(data_to_send))
    .then(res=>{
      if(res.data.status = "success"){
      setToast({type:"success", show: true, message:res.data.message});
      setOpenModal(false)
      getFunction()
      setToast(false)
       
      } else if(res.data.status = "error"){
        setToast({ type:"error",show: true,  message:res.data.message});
        
      }
    }).catch(e=>console.log(e))
  }

  return (
    <Modal
      close={setOpenModal}
      footer={false}
      title={"Edit Group"}
      visible={openModal}
    >
      <div className='animated-form'>
        <div className='form-group'>
          <label htmlFor='year' className='animated-label'>
            Group Name
          </label>
          <input
            type="text"
            id="year"
            placeholder="Group Name"
            defaultValue={openModal?.subject_name}
            className="animated-input"
            onChange={(e)=>{
              setOpenModal(
                {
                  ...openModal,
                  subject_name:e.target.value
                }
              )
            }}
          />

        </div>

        <div className='rowEndDiv'>
          {/* <span className="cancel-btn" onClick={() => setOpenModal(false)}>
            {"Cancel"}
          </span> */}
        
            <button  className='btn animated-btn btn-success' onClick={handelEdit}>
              Add
            </button>
          
        </div>

        {toast && (
          <Toast message={"No Data Passed"} type={"error"} onClose={setToast} />
        )}
      </div>
    </Modal>
  );
}

export default EditGroupModal;
