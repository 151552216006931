import React, { useState } from "react";
import Modal from "../../modal";
import axios from "axios";
import Toast from "../../toast";
import Loader from "../../loader";
import { FaPlus } from "react-icons/fa";
import "./style.css";
import { useParams } from "react-router-dom";
import { baseUrl, secondUrl } from "../../../utils/baseUrl";
import SelectComponent from "../../selectBox";
import toast, { Toaster } from "react-hot-toast";

function AddSubject({ getFunction, openModal, type,  setOpenModal }) {
  const [subjectData, setSubjectData] = useState({
    subject_name: "",
    subject_description: "", 
    have_image: "0", // By default, assuming no image is uploaded
    type: type,
    loading: false,
  });

  console.log(type);
  
  

  const saveNewSubject = async (e) => {
    

    // if (subject_name.trim() === "") {
    //   alert("يجب إدخال اسم المادة.");
    //   return;
    // }

    let data_to_send={
      subject_name:subjectData.subject_name,
      doctor_id:"2",
      have_image:1,
      type:type,
      subject_description:subjectData.subject_description,
      item_img_url:"https://res.cloudinary.com/duovxefh6/image/upload/v1727257549/WhatsApp_Image_2024-09-25_at_12.35.51_f6bf2992_yagbkw.jpg"
    }
 console.log(data_to_send);

await axios.post(
      "https://Camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/insert_subject.php",
      JSON.stringify(data_to_send)
    ).then(res=>{
      console.log(res);
      
      if(res.data.status = "success"){
        toast.success(res.data.message)
        getFunction()
        setOpenModal(false)
      } else if(res.data.status = "error"){
        toast.success(res.data.message)
        
      }
    })
    .catch(e=>console.log(e))
  };

  return (
    <>
      <Modal
        close={() => setOpenModal(false)}
        footer={false}
        title={"Add Subject"}
        visible={openModal}
      >
        <div  className="animated-form">
          <div className="form-group">
            <label htmlFor="subjectName" className="form-label">
              course Name
            </label>
            <input
              type="text"
              id="subjectName"
              placeholder="Enter course Name"
              value={subjectData.subject_name}
              onChange={(e) =>
                setSubjectData({ ...subjectData, subject_name: e.target.value })
              }
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="subjectDescription" className="form-label">
            course Description
            </label>
            <input
              type="text"
              id="subjectDescription"
              placeholder="Enter course Description"
              value={subjectData.subject_description}
              onChange={(e) =>
                setSubjectData({
                  ...subjectData,
                  subject_description: e.target.value,
                })
              }
              className="form-input"
            />
          </div>
          <div className="form-footer">
            
              <button type="submit" className="btn btn-success" style={{margin:"10px 0"}} onClick={saveNewSubject}>
                Save
              </button>
            
          </div>
        </div>
      </Modal>
      <Toaster
  position="top-center"
  reverseOrder={false}
/>
    </>
  );
}

export default AddSubject;
