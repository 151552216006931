import React, { useEffect, useState } from "react";
import Modal from "../../../modal";
import {
  addNew,
  deleteIcon,
  editIcon,
  pdfIcon,
} from "../../../../assets/svgIcons";
import "./style.css";
import DeletePdf from "./deletePDf";
import EditPdf from "./editVideo";
import axios from "axios";
function PdfOpen({ openModal, setOpenModal, getFunction }) {
  const [openDelete, setOpenDelete] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [PDFs, setPDFs] = useState([]);
  useEffect(() => {
     getAllMyVediosFor();
  }, [openModal]);
  const getAllMyVediosFor = async () => {
    const data_to_send = {
      lec_sub_id:openModal?.video_id,
      type: "general",
    };


     console.log(data_to_send);
     console.log(openModal);

    

      const response = await axios.post(
        "https://Camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/lecture_pdfs.php",
        JSON.stringify(data_to_send)
      ).then(res=>{
        console.log(res);
        
        if(res.data.status = "success"){
          setPDFs([...res.data.message])
        } else if(res.data.status = "error"){
          alert(res.data.message);
          
        }
      }).catch(e=>console.log(e))


     
   
  };
  return (
    <>
      <Modal
        close={() => setOpenModal(false)}
        footer={false}
        title={"PDF"}
        visible={openModal}
      >
        {PDFs && PDFs?.length ? (
          PDFs?.map(item=>{
            return <div className="pdfView">
            <span>{pdfIcon}</span>
            <a href={item?.pdf_link} target="_blank" rel="noopener noreferrer">
                    {item?.pdf_name && item?.pdf_name.length > 0 ? (
                        item.pdf_name
                    ) : (
                        "pdf dosen't have name"
                    )}
                </a>
            <pre>
              <p onClick={() => setOpenDelete(item)}>{deleteIcon}</p>
             
            </pre>
          </div>
          })
          
        ) : null}
        <p
          className="createnew"
          onClick={() => {
            setOpenEdit(openModal);
            setIsEdit(false);
            console.log("tesr");

          }}
        >
          <span>{addNew}</span> <span>Add / Edit PDF</span>
        </p>
      </Modal>
      <EditPdf
        getFunction={getAllMyVediosFor}
        openModal={openEdit}
        setOpenModal={setOpenEdit}
        edit={isEdit}
      />{" "}
      <DeletePdf
        getFunction={getAllMyVediosFor}
        openModal={openDelete}
        setOpenModal={setOpenDelete}
      />
    </>
  );
}

export default PdfOpen;
