import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../utils/baseUrl";
import { uploadPdf } from "../../../../utils/functions/upload_pdf";
import Loader from "../../../loader";
import Modal from "../../../modal";
import Toast from "../../../toast";
import { useParams } from "react-router-dom";
import axios from "axios";

function EditPdf({ edit, getFunction, openModal, setOpenModal }) {
  const [PdfData, setPdfData] = useState({
    loading: false,
  });

  const [toast, setToast] = useState(false);

  useEffect(() => {
    if (edit) {
      setPdfData({
        ...openModal,

        loading: false,
      });
    } else {
      setPdfData({
        ...{},

        loading: false,
      });
    }
  }, [openModal, edit]);
  const { subject, type } = useParams();

  const AddEditPdf = async () => { 
    let pdf = null;
    if (PdfData?.file) {
      pdf = await uploadPdf(PdfData?.file);
    }

    let data_to_send = { 
      pdf_name: PdfData?.pdf_name,
      pdf_link: pdf,
      pdf_sub_id: openModal?.video_id, // Assuming it's the subject id
      type: "general", // Using selectedType state
    };


    await axios.post(baseUrl + 'add_pdf_types.php' , JSON.stringify(data_to_send))
    .then(res=>{
      console.log(res);
      
      if(res.data = "success"){
        setToast({type:"success", show: true, message: "تم اضافة الملف بنجاح" });
        setOpenModal(false)
        getFunction()
      } else if(res.data = "error"){
        setToast({ type:"error",show: true, message: "حدث خطء في اضافة الملف" });
        
      }
    }).catch(e=>console.log(e))
   
  };

  return (
    <>
      <Modal
        close={() => setOpenModal(false)}
        footer={false}
        title={edit ? "Edit Pdf" : "Add PDF"}
        visible={openModal}
      >
        <form onSubmit={(e) => e.preventDefault()} className="animated-form">
          
          <div className="form-group">
            <label htmlFor="PdfFile" className="animated-label">
              PDF File
            </label>
            <input
              type="file"
              id="PdfFile"
              placeholder="PDF File"
              onChange={(e) =>
                setPdfData({ ...PdfData, file: e.target.files[0] })
              }
              className="animated-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="PdfFile" className="animated-label">
              PDF name
            </label>
            <input
              type="text"
              defaultValue={edit ? openModal.pdf_name : " "}
              id="PdfFile"
              placeholder="PDF File"
              onChange={(e) =>
                setPdfData({ ...PdfData, pdf_name: e.target.value })
              }
              className="animated-input"
            />
          </div>
          <div className="rowEndDiv">
            {PdfData?.loading ? (
              <Loader />
            ) : (
              <button
                onClick={() => AddEditPdf()}
                type="submit"
                className="btn animated-btn btn-success"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </Modal>
      {toast && (
        <Toast
          message={toast?.message}
          type={toast?.type}
          onClose={() => setToast(false)}
        />
      )}
    </>
  );
}

export default EditPdf;
