import React, { useEffect, useState } from "react";
import Modal from "../../../modal";
import axios from "axios";
import Toast from "../../../toast";
import Loader from "../../../loader";
import { baseUrl } from "../../../../utils/baseUrl";

function DeleteVideo({ getFunction, openModal, setOpenModal }) {
  

  const [toast, setToast] = useState(false);

  const deleteVideo = (e) => {
    e.preventDefault();

    let data_to_send={
      video_id:openModal.video_id
    }

     console.log(data_to_send,openModal);
    
    
      
      axios
        .post(baseUrl+`delete_video.php`,JSON.stringify(data_to_send)) 
        .then(res=>{
          if(res.data.status = "success"){
          setToast({type:"success", show: true, message: "تم مسح الفيديو بنجاح" });
          setOpenModal(false)
          getFunction()
           
          } else if(res.data.status = "error"){
            setToast({ type:"error",show: true, message: "حدث خطء في حذف الفيديو" });
            
          }
        }).catch(e=>console.log(e))
    
  };



  return (
    <Modal
      close={() => setOpenModal(false)}
      footer={false}
      title={"Delete Video"}
      visible={!!openModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this video?</h3>
        <p className="warning-message">
          This action cannot be undone. Please confirm that you want to delete
          the following video:
        </p>
        <div className="video-details">
          <strong>Video Name:</strong> {openModal?.video_title}
        </div>
        <div className="rowEndDiv">
         
            <>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteVideo}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
            </>
          
        </div>
        {toast && (
          <Toast
            message={"An error occurred. Please try again."}
            type={"error"}
            onClose={() => setToast(false)}
          />
        )}
      </div>
    </Modal>
  );
}

export default DeleteVideo;
