import React, { useState } from "react";
import Modal from "../../modal";
import axios from "axios";
import Toast from "../../toast";
import Loader from "../../loader";
import { FaPlus, FaTrash } from "react-icons/fa";
import "./style.css";
import { useParams } from "react-router-dom";

function AddLecture({ getFunction, openModal, setOpenModal }) {
  const [lectureData, setLectureData] = useState({
    name: "",
    image: null,
    description: "",
    type: "", // assuming 'new' type for demonstration
    subject_id: 0,
    loading: false,
  });
  const { level_id, subject} = useParams();
  const [toast, setToast] = useState(false);

  

  const handleImageChange = (e) => {
    setLectureData({ ...lectureData, image: e.target.files[0] });
  };

  const removeImage = () => {
    setLectureData({ ...lectureData, image: null });
  };

  const addNew = () => {




      const formData = new FormData();
      // if (lectureData.image) {
      //   formData.append("image", lectureData.image, "avatar.png"); // assuming filename
      // }
      

      let data_to_send={
        lec_sub_id:subject,
        lec_title:lectureData?.name,
        lec_descriprion:lectureData?.description,
        level_id:level_id,
      }

       console.log(data_to_send);
      
      

      axios
        .post(
          "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/add_leceture_by_level.php",
          JSON.stringify(data_to_send))
          .then(res=>{
            if(res.data.status = "success"){
            setToast({type:"success", show: true, message:res.data.message });
            getFunction()
            setOpenModal(false)
            setToast(false)
            } else if(res.data.status = "error"){
              setToast({ type:"error",show: true, message:res.data.message });
              
            }
          }).finally(()=>{
            setToast(false)
          })
          .catch(e=>console.log(e))
        
    
  };

  return (
    <Modal
      close={setOpenModal}
      footer={false}
      title={"Add Lecture"}
      visible={openModal}
    >
      <div  className="animated-form">
        <div className="form-group">
          <label htmlFor="lectureName" className="form-label">
            Lecture Name
          </label>
          <input
            type="text"
            id="lectureName"
            placeholder="Enter Lecture Name"
            onChange={(e) =>
              setLectureData({ ...lectureData, name: e.target.value })
            }
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lecturenumber" className="form-label">
            Lecture Number
          </label>
          <input
            type="text"
            id="lecturenumber"
            placeholder="Enter Lecture number"
            onChange={(e) =>
              setLectureData({
                ...lectureData,
                lec_arrangement: e.target.value,
              })
            }
            className="form-input"
          />
        </div>{" "}
        {/* <div className="form-group">
          <label htmlFor="lectureImage" className="form-label">
            Lecture Image (Optional)
          </label>
          <input
            type="file"
            id="lectureImage"
            onChange={handleImageChange}
            className="form-input"
          />
          {lectureData.image && (
            <div className="image-preview">
              <img
                src={URL.createObjectURL(lectureData.image)}
                alt="Lecture Preview"
              />
              <button
                type="button"
                onClick={removeImage}
                className="remove-image-btn"
              >
                <FaTrash />
              </button>
            </div>
          )}
        </div> */}
        <div className="form-group">
          <label htmlFor="lectureDescription" className="form-label">
            Description
          </label>
          <textarea
            id="lectureDescription"
            placeholder="Enter Lecture Description"
            onChange={(e) =>
              setLectureData({ ...lectureData, description: e.target.value })
            }
            className="form-input"
            rows="4"
          />
        </div>
        <div className="form-footer">
          
            <button  className="btn btn-success" onClick={()=>{
              addNew()
            }}>
              Save
            </button>
          
        </div>
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(false)}
          />
        )}
      </div>
    </Modal>
  );
}

export default AddLecture;
