import React, { useEffect, useState } from "react";
import { baseUrl, secondUrl } from "../../../../utils/baseUrl";
import Loader from "../../../loader";
import Modal from "../../../modal";
import Toast from "../../../toast";
import axios from "axios";

function DeletePdf({ getFunction, openModal, setOpenModal }) {
  const [PdfData, setPdfData] = useState({
    ...openModal,
    loading: false,
  });

  const [toast, setToast] = useState(false);

  useEffect(() => {
    if (openModal) {
      setPdfData({
        ...openModal,
        loading: false,
      });
    }
  }, [openModal]);
  const AddEditPdf = async () => {


    let data_to_send = {
      pdf_id: openModal,
    };

     console.log(data_to_send ,openModal);
    

    await axios.post(baseUrl + "delete_pdf.php" , JSON.stringify(data_to_send))
    .then(res=>{
      if(res.data.status = "success"){
      setToast({type:"success", show: true, message: "تم مسح الملف بنجاح" });
      setOpenModal(false)
      getFunction()
       
      } else if(res.data.status = "error"){
        setToast({ type:"error",show: true, message: "حدث خطء في حذف الملف" });
        
      }
    }).catch(e=>console.log(e))
    
  };

  return (
    <>
      <Modal
        close={() => setOpenModal(false)}
        footer={false}
        title={"Delete Pdf"}
        visible={!!openModal}
      >
        <div className="delete-warning">
          <h3>Are you sure you want to delete this Pdf?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the Pdf.
          </p>
          {/* <div className="Pdf-details">
            <strong>Pdf Name:</strong> {PdfData?.name}
          </div> */}
          <div className="rowEndDiv">
            {PdfData?.loading ? (
              <Loader />
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => AddEditPdf()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setOpenModal(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
      {toast && (
        <Toast
          message={toast?.message}
          type={toast?.type}
          onClose={() => setToast(false)}
        />
      )}
    </>
  );
}

export default DeletePdf;
