import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table";
import "./style.css";
import { baseUrl, secondUrl } from "../../utils/baseUrl";
import Toast from "../../components/toast";
import ChangeGroup from "../../components/students/changegroup";
import SelectComponent from "../../components/selectBox";
import Modal from "../../components/modal";
import axios from "axios";
import DropMenu from "../../components/dropmenu";

// ConfirmModal Component
function ConfirmModal({ visible, onClose, onConfirm, message }) {
  if (!visible) return null;

  return (
    <Modal
      close={onClose}
      footer={false}
      title={"Confirm Action"}
      visible={visible}
    >
      <div className="confirm-modal">
        <p>{message}</p>
        <div className="confirm-modal-buttons">
          <button className="btn btn-danger" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}

function Students() {
  const [toast, setToast] = useState(false);
  const [openChangeGroup, setOpenChangeGroup] = useState(false);
  const [students, setStudents] = useState(null);
  const [openAddSubCard, setOpenAddSubCard] = useState(false);
  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [EditStudentModal, setEditStudentModal] = useState(false);
  const [DeletModal, setDeletModal] = useState(false);
  const [ResetDeviceModal, setResetDeviceModal] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [years, setYears] = useState([]);
  const [Subjects, setSubjects] = useState([]);

  const [RowData, setRowData] = useState([]);
  const [NewSubscriptionData, setNewSubscriptionData] = useState({
    student_subject_id: null,
    subscription_end: null,
    type:null
  });

  const [groups, setGroups] = useState([]);
  const [packages, setPackages] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    centerType: "",
    gen_id: "",
    group_id: "",
    package_id: "",
  });
  const [confirmAction, setConfirmAction] = useState({
    visible: false,
    message: "",
    onConfirm: null,
  }); // State for confirmation modal

  const [NewStudentData, setNewStudentData] = useState({
    student_email: "",
    student_password: "",
    student_name: "",
    generation_id: "",
    student_phone: "",
    phone: "",
  });

  useEffect(() => {
    getStudents();
    getYears();
  }, []);

  const getStudents = async () => {
    try {
      const response = await fetch(
        "https://camp-coding.tech/teachersApp2024/Ezz_Zatona/doctor/home/select_students.php"
      );
      const data = await response.json();
      setStudents(data);
    } catch (err) {
      setStudents([]);
    }
  };

  const getYears = async () => {
    try {
      const yearsData = await fetch(
        baseUrl + "subscriptions/select_genrations.php"
      );
      const data = await yearsData.json();
      setYears(data);
    } catch (err) {
      setYears([]);
    }
  };

  useEffect(() => {
    if (subscriptionData.gen_id) {
      const selectedYear = years.find(
        (year) => year.gen_id === subscriptionData.gen_id
      );
      setGroups(selectedYear?.groups || []);
    }
  }, [subscriptionData.gen_id]);

  useEffect(() => {
    if (subscriptionData.group_id) {
      const selectedGroup = groups.find(
        (group) => group.group_id === subscriptionData.group_id
      );
      setPackages(selectedGroup?.packages || []);
    }
  }, [subscriptionData.group_id]);

  // Trigger the confirm modal for the activation/deactivation
  const triggerConfirmModal = (message, onConfirm) => {
    setConfirmAction({
      visible: true,
      message,
      onConfirm,
    });
  };

  const getSubjects = async () => {
    await axios
      .post(baseUrl + "select_subjects.php")
      .then((res) => {
        if ((res.data.status = "success")) {
          setSubjects([...res.data.message]);
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        }
      })
      .finally(() => {
        setToast(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getSubjects();
    console.log(groups);
  }, []);

  const handleCreateSubscription = async () => {
    let data_to_send = {
      student_id: RowData?.student_id,
      student_subject_id: NewSubscriptionData?.student_subject_id,
      subscription_end: NewSubscriptionData?.subscription_end,
      type:NewSubscriptionData?.type
    };

     console.log(data_to_send,NewSubscriptionData);

    await axios
      .post(baseUrl + "add_sub_student.php",JSON.stringify(data_to_send))
      .then((res) => {
        console.log(res);
        
        if ((res.data.status = "success")) {
          setToast({ type: "success", show: true, message: res.data.message });
          setOpenAddSubCard(false);
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        }
      })
      .finally(() => {
        setToast(false);
        setNewSubscriptionData(
          {
            ...NewSubscriptionData,
            student_subject_id:null,
            type:null
          }
        )
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "student_name",
      search: true,
    },
    {
      key: "student_phone",
      title: "Student Phone",
      dataIndex: "student_phone",
      render: (e, row) => {
        return <span>{row?.student_phone}</span>;
      },
    },
    {
      key: "student_status",
      title: "student_status",
      dataIndex: "student_status",
      render: (e, row) => {
        return <span>{row?.student_status}</span>;
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "student_email",
      render: (e, row) => {
        return <span>{row?.student_email}</span>;
      },
    },
 
    {
      key: "actions",
      title: "Actions",
      render: (text, row) => (
        <DropMenu child={"Actions"}>
          <div className="actions-btns">
            <div
              className="open-btn c-pointer text-primary"
              onClick={() => {
                setEditStudentModal(true);
                setRowData(row);
              }}
            >
              <div className="btn btn-warning">Edit</div>
            </div>
            <div
              className="open-btn c-pointer text-primary"
              onClick={() => {
                setDeletModal(true);
                setRowData(row);
              }}
            >
              <div className="btn btn-dark">delete</div>
            </div>
            <div
              className="open-btn c-pointer text-primary"
              onClick={async () => {
                ResetDevice(row?.student_id);
              }}
            >
              <div className="btn btn-danger">reset device</div>
            </div>
            <button
              className="btn btn-success"
              onClick={() => {
                setOpenAddSubCard(true);
                setRowData(row);
              }}
            >
              Add subscreption
            </button>
          </div>
        </DropMenu>
      ),
    },
  ];

  const handelAddStudent = async () => {
    let data_to_send = {
      student_email: NewStudentData.student_email,
      student_password: NewStudentData.student_password,
      student_name: NewStudentData.student_name,
      student_phone: NewStudentData.student_phone,
      student_status: "approved",
    };

    console.log(data_to_send);

    await axios
      .post(baseUrl + "add_student.php", JSON.stringify(data_to_send))
      .then((res) => {
        console.log(res);

        if ((res.data.status = "success")) {
          setOpenAddStudent(false);
          setToast({ type: "success", show: true, message: res.data.message });
          getStudents();
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        } else {
          setToast({
            type: "error",
            show: true,
            message: "someThing went wrong please try again",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handelUpdateStudent = async () => {
    let data_to_send = {
      student_id: RowData?.student_id,
      student_email: RowData?.student_email,
      student_name: RowData?.student_name,
      student_phone: RowData?.student_phone,
    };

    console.log(data_to_send);

    await axios
      .post(baseUrl + "update_student.php", JSON.stringify(data_to_send))
      .then((res) => {
        console.log(res);

        if ((res.data.status = "success")) {
          setEditStudentModal(false);
          setToast({ type: "success", show: true, message: res.data.message });
          getStudents();
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        } else {
          setToast({
            type: "error",
            show: true,
            message: "someThing went wrong please try again",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const DeleteStudent = async () => {
    let data_to_send = {
      student_id: RowData?.student_id,
    };

    console.log(data_to_send);

    await axios
      .post(baseUrl + "delete.student.php", JSON.stringify(data_to_send))
      .then((res) => {
        console.log(res);

        if ((res.data.status = "success")) {
          setDeletModal(false);
          setToast({ type: "success", show: true, message: res.data.message });
          getStudents();
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        } else {
          setToast({
            type: "error",
            show: true,
            message: "someThing went wrong please try again",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const ResetDevice = async (student_id) => {
    let data_to_send = {
      student_id: student_id,
    };

    await axios
      .post(baseUrl + "log_out_student.php", JSON.stringify(data_to_send))
      .then((res) => {
        console.log(res);

        if ((res.data.status = "success")) {
          setDeletModal(false);
          setToast({ type: "success", show: true, message: res.data.message });
          getStudents();
        } else if ((res.data.status = "error")) {
          setToast({ type: "error", show: true, message: res.data.message });
        } else {
          setToast({
            type: "error",
            show: true,
            message: "someThing went wrong please try again",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="students">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Students</h1>
        <button
          className="btn btn-success"
          onClick={() => {
            setOpenAddStudent(true);
          }}
        >
          Add student
        </button>
      </div>
      <CustomTable dataSource={students} columns={columns} />
      <ChangeGroup
        userData={openChangeGroup}
        openModal={openChangeGroup}
        setOpenModal={setOpenChangeGroup}
      />
      <ConfirmModal
        visible={confirmAction.visible}
        message={confirmAction.message}
        onClose={() => setConfirmAction({ ...confirmAction, visible: false })}
        onConfirm={() => {
          confirmAction.onConfirm();
          setConfirmAction({ ...confirmAction, visible: false });
        }}
      />

      {/* Render Add Subscription Form if a student is selected */}
      <Modal
        close={setOpenAddStudent}
        footer={false}
        title={"Add student"}
        visible={openAddStudent}
      >
        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student name
          </label>
          <input
            type="text"
            id="studentName"
            placeholder="student name"
            onChange={(e) =>
              setNewStudentData({
                ...NewStudentData,
                student_name: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student email
          </label>
          <input
            type="text"
            id="studentName"
            placeholder="student email"
            onChange={(e) =>
              setNewStudentData({
                ...NewStudentData,
                student_email: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student password
          </label>
          <input
            type="text"
            id="studentName"
            placeholder="student name"
            onChange={(e) =>
              setNewStudentData({
                ...NewStudentData,
                student_password: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student phone
          </label>
          <input
            type="number"
            id="studentName"
            placeholder="student phone"
            onChange={(e) =>
              setNewStudentData({
                ...NewStudentData,
                student_phone: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <button
          onClick={handelAddStudent}
          className="btn btn-success "
          style={{ margin: "10px 0" }}
        >
          Add student
        </button>
      </Modal>

      <Modal
        close={setEditStudentModal}
        footer={false}
        title={"Edit student"}
        visible={EditStudentModal}
      >
        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student name
          </label>
          <input
            type="text"
            id="studentName"
            placeholder="student name"
            defaultValue={RowData?.student_name}
            onChange={(e) =>
              setRowData({
                ...RowData,
                student_name: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student email
          </label>
          <input
            type="text"
            id="studentName"
            defaultValue={RowData?.student_email}
            placeholder="student email"
            onChange={(e) =>
              setRowData({
                ...RowData,
                student_email: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="viemoId" className="animated-label">
            student phone
          </label>
          <input
            type="number"
            id="studentName"
            defaultValue={RowData?.student_phone}
            placeholder="student phone"
            onChange={(e) =>
              setRowData({
                ...RowData,
                student_phone: e.target.value,
              })
            }
            className="animated-input"
          />
        </div>

        <button
          onClick={handelUpdateStudent}
          className="btn btn-success "
          style={{ margin: "10px 0" }}
        >
          Edit student
        </button>
      </Modal>

      <Modal
        close={() => setDeletModal(false)}
        footer={false}
        title={"Delete Video"}
        visible={DeletModal}
      >
        <div className="delete-warning">
          <h3>Are you sure you want to delete this student?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following student:
          </p>
          <div className="video-details">
            <strong>student Name:</strong> {RowData?.student_name}
          </div>
          <div className="rowEndDiv">
            <button
              type="button"
              className="btn btn-danger"
              onClick={DeleteStudent}
            >
              Delete
            </button>
          </div>
          {toast && (
            <Toast
              message={"An error occurred. Please try again."}
              type={"error"}
              onClose={() => setToast(false)}
            />
          )}
        </div>
      </Modal>

      <Modal
        close={setOpenAddSubCard}
        footer={false}
        title={"Add Subscription"}
        visible={openAddSubCard}
      >
        <div className="subscription-form-section">
          <h2>Add Subscription for {RowData?.student_name}</h2>
          <div>
            <div className="form-group">
              <label htmlFor="centerType">student id</label>
              <input type="number" defaultValue={RowData?.student_id} />
            </div>
            <div className="form-group">
              <label htmlFor="package_id">Subject</label>
              <SelectComponent
                options={Subjects.map((pkg) => ({
                  label: pkg.subject_name,
                  value: pkg.subject_id,
                  type:pkg.type
                }))}
                value={{ value: NewSubscriptionData.student_subject_id }}
                onChange={(e) => {
                  setNewSubscriptionData(
                    {
                      ...NewSubscriptionData,
                      student_subject_id:e.value,
                      type:e.type

                    }
                  )
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="centerType">subscription end date</label>
              <input type="date" onChange={(e) => {
                setNewSubscriptionData(
                  {
                    ...NewSubscriptionData,
                    subscription_end:e.target.value
                  }
                )
              }} />
            </div>

            <button
              type="submit"
              className="btn btn-success"
              onClick={handleCreateSubscription}
            >
              Create Subscription
            </button>
          </div>
        </div>
      </Modal>

      {toast && (
        <Toast
          message={toast?.message}
          type={toast?.type}
          onClose={() => setToast(false)}
        />
      )}
    </div>
  );
}

export default Students;
